import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import HalfBannerDescription, {
  HalfBannerDescriptionType,
} from 'components/organisms/half-banner-description';
import IndustriesAbout from 'components/organisms/industries-about';
import ManagerSection, { ManagerSectionType } from 'components/organisms/manager-section';
import PostsSectionRowSlider, {
  PostsSectionRowSliderType,
} from 'components/organisms/posts-section-row-slider';
import TextBannerCardSection, {
  TextBannerCardType,
} from 'components/organisms/text-banner-card-section';
import BannerCard from 'components/molecules/banner-card';
import IndustriesBanner, { IndustriesBannerType } from 'components/molecules/industries-banner';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';

import CaseStudies, { CaseStudiesType } from '../components/organisms/case-studies/case-studies';
import RecruitmentProcessSteps, {
  RecruitmentProcessStepsType,
} from '../components/organisms/recruitment-process-steps';

type PayrollDescriptionType = {
  headingService: string;
  textService: string;
  problemsList: { text: string }[];
} & TextBannerCardType;

type DataProps = {
  commonComponents: {
    commonComponentsData: {
      letsTalkBanner: LetsTalkBannerType;
    };
  };
  offersPayrollStaffPage: {
    template: {
      offersPayrollStaffData: {
        bannerQuaternary: IndustriesBannerType;
        payrollDescription: PayrollDescriptionType;
        serviceDescription: TextBannerCardType;
        advantagesSection: TextBannerCardType;
        implementProcess: RecruitmentProcessStepsType;
        payrollTeam: ManagerSectionType;
        knowHowArticles: PostsSectionRowSliderType;
        joinToHrk: HalfBannerDescriptionType;
        payrollCaseStudies: CaseStudiesType;
        otherServicesBlock: TextBannerCardType;
      };
    };
  };
};

export const OffersItContractingPage = ({
  pageContext,
  data: {
    offersPayrollStaffPage: {
      template: {
        offersPayrollStaffData: {
          bannerQuaternary,
          payrollDescription,
          advantagesSection,
          implementProcess,
          payrollTeam,
          knowHowArticles,
          joinToHrk,
          payrollCaseStudies,
          otherServicesBlock,
        },
      },
    },
    commonComponents: {
      commonComponentsData: { letsTalkBanner },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <IndustriesBanner {...bannerQuaternary} pageContext={pageContext} isWhite />
    <TextBannerCardSection
      cardList={payrollDescription.cardList}
      heading={payrollDescription.heading}
      text={payrollDescription.text}
      bgColor={theme.colors.gray0}
      margin={`-${theme.gap.xl} auto`}
      padding={`${theme.gap.xxl} 0`}
      textColumn
    >
      {payrollDescription.cardList
        ? payrollDescription.cardList.map((item) => (
            <BannerCard {...item} key={item.text} borderColor={theme.colors.primary50} bigTitle />
          ))
        : null}
    </TextBannerCardSection>
    <IndustriesAbout
      heading={payrollDescription.headingService}
      problemsList={payrollDescription.problemsList}
      text={payrollDescription.textService}
      maxWidth={theme.wrapper}
      bgColor={theme.colors.gray0}
      margin="0"
      padding={`0 0 ${theme.gap.xl} 0 `}
      smallHeading
    />
    <TextBannerCardSection {...advantagesSection}>
      {advantagesSection.cardList
        ? advantagesSection.cardList.map((item) => (
            <BannerCard
              {...item}
              key={item.text}
              padding={theme.gap.md}
              mobilePadding={theme.gap.sm}
            />
          ))
        : null}
    </TextBannerCardSection>
    <ManagerSection {...payrollTeam} margin={`0 `} />
    <RecruitmentProcessSteps bgColor={theme.colors.white} {...implementProcess} />
    <CaseStudies {...payrollCaseStudies} />
    <PostsSectionRowSlider
      {...knowHowArticles}
      bgColor={theme.colors.gray0}
      margin={`${theme.gap.xl} 0 0 0`}
      mobileMargin={`${theme.gap.md} 0 0 0`}
    />
    <LetsTalkBanner {...letsTalkBanner} margin={`0 auto ${theme.gap.xl}`} />
    <TextBannerCardSection {...otherServicesBlock} textColumn>
      {otherServicesBlock && otherServicesBlock.cardList
        ? otherServicesBlock.cardList.map((item) => <BannerCard {...item} key={item.text} />)
        : null}
    </TextBannerCardSection>
    <HalfBannerDescription {...joinToHrk} />
  </Layout>
);

export const query = graphql`
  query OffersPayrollStaffPage($id: String!, $commonComponentsSlug: String!) {
    offersPayrollStaffPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_OffersPayrollStaff {
          offersPayrollStaffData {
            bannerQuaternary {
              text
              heading
              image {
                ...WpImageFragment
              }
            }
            payrollDescription {
              heading
              text
              headingService
              textService
              cardList: stats {
                heading: value
                text: description
              }
              problemsList: bulletsList {
                text
              }
            }
            advantagesSection {
              heading
              subTitle
              text
              cardList: advantagesList {
                heading
                icon {
                  name
                }
              }
            }
            payrollTeam {
              backgroundImage {
                ...WpImageFragment
              }
              businessCards {
                businessCard {
                  text
                  fullName
                  position
                  positionTitle
                  text
                  contactList {
                    type
                    link {
                      ariaLabel
                      wpInternal {
                        ... on WpPage {
                          id
                          uri
                        }
                      }
                      external {
                        target
                        url
                      }
                    }
                  }
                  image {
                    ...WpImageFragment
                  }
                }
              }
            }
            implementProcess {
              subTitle
              stepText: text
              heading
              processSteps {
                text
                heading
              }
            }
            knowHowArticles {
              heading
              subTitle
              text
              posts: choosenPosts {
                ... on WpKnow_how {
                  id
                  title
                  link
                  date(formatString: "DD.MM.YYYY", locale: "PL")
                  categories {
                    nodes {
                      name
                      slug
                    }
                  }
                  tags {
                    nodes {
                      name
                      uri
                    }
                  }
                  featuredImage {
                    node {
                      ...WpImageFragment
                    }
                  }
                }
              }
            }
            payrollCaseStudies {
              subTitle
              heading
              imageCaseStudy {
                ...WpImageFragment
              }
              caseStudyPost {
                ... on WpKnow_how {
                  id
                  title
                  link
                  date(formatString: "DD.MM.YYYY", locale: "PL")
                  knowHowPostData {
                    postTemplate {
                      ... on WpKnow_how_Knowhowpostdata_PostTemplate_Author {
                        firstName
                        lastName
                        position
                      }
                    }
                  }
                  categories {
                    nodes {
                      name
                    }
                  }
                  tags {
                    nodes {
                      name
                    }
                  }
                  featuredImage {
                    node {
                      ...WpImageFragment
                    }
                  }
                }
              }
            }
            joinToHrk {
              bannerHeading: heading
              bannerSubHeading: subTitle
              bannerText: text
              bannerLinkButton: linkButton {
                icon {
                  name
                }
                label
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  external {
                    title
                    url
                  }
                }
              }
              bannerImage: image {
                ...WpImageFragment
              }
            }
            otherServicesBlock {
              subTitle
              heading
              text
              cardList: servicesList {
                heading
                text
                icon {
                  name
                }
                linkButton {
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        id
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default OffersItContractingPage;
